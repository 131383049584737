import React from "react";
import "./header.css"

export default function Header(props) {

  return (
    <header className="header">
       <h1>Emociones</h1>
      </header>
  )
}